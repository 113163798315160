<template>
<a class='link' href="./goc.pdf" target="_blank">View Portfolio </a>
<div class="boundary">
<img alt="background" src="leadImage.jpeg">
</div>
</template>

<script>

export default {

}
</script>

<style>
body{
  margin:0;
  background-color:black;
}
img{
  width:auto;
  height:100vh;
}
.boundary{
  display:flex;
  justify-content: center;
  width:100vw;
  height:100vh;
  overflow:hidden;
}
.link{
  color:white;
  position:absolute;
  bottom:2em;
  right:2em;
  font-size:2em;
  text-decoration: none;
}
.link:hover{
  text-decoration: underline;
}
</style>
